<template>
  <div>
    <div class="title">
      <span>订单/交易设置</span>
    </div>
    <div class="content">
      <div style="width:350px;">
        <span style="color:red">*</span>签收后自动确认收货时间
      </div>
      <div>
        <div>
          签收后
          <el-input
            v-model="info.confirm_day"
            size="mini"
            class="input"
          ></el-input
          >天, 自动确认收货
        </div>
        <div style="font-size:12px;margin-top:10px;color:#C0BEC4">
          修改后将对新产生的订单立即生效,
          已产生的订单自动确认收货时间同时将在以发货时的设置为准
        </div>
      </div>
    </div>
    <div class="content">
      <div style="width:350px;">
        <span style="color:red">*</span>买家申请售后限制
      </div>
      <div>
        买家确认收货
        <el-input v-model="info.sale_day" size="mini" class="input"></el-input
        >天, 不支持买家申请售后
      </div>
    </div>
    <div class="content">
      <div style="width:350px;">
        <span style="color:red">*</span>买家退货后商家自动确认收货时间
      </div>
      <div>
        买家签收后
        <el-input
          v-model="info.refund_confirm_day"
          size="mini"
          class="input"
        ></el-input
        >天, 自动确认收货
      </div>
    </div>
    <div class="content">
      <div style="width:350px;">
        <span style="color:red">*</span>运费类型
      </div>
      <div>
        <el-radio v-model="info.express_type" :label="1">统一运费</el-radio>
        <el-radio v-model="info.express_type" :label="2">运费模板</el-radio>
        <el-select
          v-model="info.express_template_id"
          placeholder="请选择运费模板"
          size="mini"
          filterable
          clearable
          :disabled="info.express_type == 1"
        >
          <el-option
            v-for="item in expressSelect"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="content" v-if="info.express_type == 1">
      <div style="width:350px;">
        <span style="color:red">*</span>运费金额
      </div>
      <div>
        <el-input
          v-model="info.express_fee"
          size="mini"
          class="input"
        ></el-input>
      </div>
    </div>
    <div class="title" style="margin:30px 0;">
      <span>收货地址设置</span>
    </div>
    <div class="content">
      <div style="width:350px;"><span style="color:red">*</span>联系人</div>
      <div>
        <el-input v-model="info.contact" size="mini" class="input1"></el-input>
      </div>
    </div>
    <!-- <el-form-item prop="ship_type" label="运费" required>
        <el-radio v-model="ruleForm.ship_type" :label="1">统一运费</el-radio>
        <el-radio v-model="ruleForm.ship_type" :label="2">运费模板</el-radio>
        <el-select
          v-model="ruleForm.ship_template_id"
          placeholder="请选择运费模板"
          size="mini"
          filterable
          clearable
          :disabled="ruleForm.ship_type == 1"
        >
          <el-option
            v-for="item in expressSelect"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item> -->
    <div class="content">
      <div style="width:350px;">
        <span style="color:red">*</span>联系电话
      </div>
      <div>
        <el-input
          v-model="info.telephone"
          size="mini"
          class="input1"
        ></el-input>
      </div>
    </div>
    <div class="content">
      <div style="width:350px;">
        <span style="color:red">*</span>联系地址
      </div>
      <div>
        <el-cascader
          style="width:300px"
          :value="info.addressList"
          placeholder="选择省市区"
          :options="addressList"
          size="mini"
          :props="props"
          clearable
          @change="changeAddress"
          filterable
        ></el-cascader>
      </div>
    </div>
    <div class="content">
      <div style="width:350px;">
        <span style="color:red">*</span>详细地址
      </div>
      <div>
        <el-input
          v-model="info.address_detail"
          size="mini"
          class="input1"
        ></el-input>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" size="mini" @click="saveMallConfig"
        >保 存</el-button
      >
    </div>
  </div>
</template>

<script>
import { getMallConfig, saveMallConfig } from "@/newApi/template";
import { getExpressSelect } from "@/newApi/commit";
import address from "@/utils/address.js";
export default {
  data() {
    return {
      info: {},
      expressSelect: [],
      addressList: address,
      props: {
        label: "name",
        value: "code",
      },
    };
  },
  created() {
    this.getInfo();
    this._getExpressSelect();
    //去掉this.addressList最外面的[]
    let addressList = []
    console.log(this.addressList)
    this.addressList.forEach(item=>{
      item.children.forEach(item1=>{
        addressList.push(item1)
      })
    })
    this.addressList = addressList
  },
  methods: {
    _getExpressSelect() {
      getExpressSelect().then((res) => {
        if (res.code == 200) {
          this.expressSelect = res.data;
        }
      });
    },
    saveMallConfig() {
      saveMallConfig(this.info).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeAddress(val) {
      console.log(val);
      this.info.province_id = val[0];
      this.info.city_id = val[1];
      this.info.area_id = val[2];
    },
    getInfo() {
      getMallConfig().then((res) => {
        this.info = res.data;
        this.info.addressList = [
          this.info.province_id,
          this.info.city_id,
          this.info.area_id,
        ];
        console.log(this.info)
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  padding: 7px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #efefef;
}
.content {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 0 20px;
}
.input {
  width: 100px;
  margin: 0 10px;
}
.input1 {
  width: 200px;
  margin: 0 10px;
}
.footer {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
</style>
