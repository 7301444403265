import request from '@/utils/request'

//运费模板列表
export function getExpressIndex(params) {
    return request({
        url: '/admin/express-index',
        method: 'GET',
        params
    })
}

//运费模板详情
export function getExpressInfo(params) {
    return request({
        url: '/admin/express-info/' + params.id,
        method: 'GET',
        params
    })
}
//修改运费模板
export function putExpressEdit(data) {
    return request({
        url: '/admin/express-edit/' + data.id,
        method: 'put',
        data
    })
}
//添加运费模板
export function postExpressAdd(data) {
    return request({
        url: '/admin/express-add',
        method: 'post',
        data
    })
}
//删除运费模板
export function deleteExpress(data) {
    return request({
        url: '/admin/express-delete',
        method: 'delete',
        data
    })
}
//获取商品配置
export function getMallConfig(params) {
    return request({
        url: '/admin/getMallConfig',
        method: 'GET',
        params
    })
}
//编辑商品配置
export function saveMallConfig(data) {
    return request({
        url: '/admin/saveMallConfig',
        method: 'POST',
        data
    })
}
